import axios from 'axios';
import { useEffect, useState, createElement } from 'react';
import './App.css';

const { mapkit } = global
mapkit.init({
  authorizationCallback: function (done) {
    done("eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkI5OEI1NlhOTjQifQ.eyJpc3MiOiJKTUZXTVpYVTVIIiwiaWF0IjoxNjc5OTMxMTkxLCJleHAiOjE2OTE0NTI4MDAsIm9yaWdpbiI6IioifQ.jL2Un3VPmCit3Hv2XWvK7dhNJ1AzXG361HW2U37Ub9yhY768Jd79-91rOeQX7q_qdi5qtEBfmQfSlS_HAOBpRQ");
  }
});
// console.log(mapkit)

function App() {
  // const map = new mapkit.Map("map");
  const [search, setSearch] = useState('')
  const [long, setLong] = useState('')
  const [lat, setLat] = useState('')
  const [map, setMap] = useState()

  function success(pos) {
    var crd = pos.coords;

    // console.log("Your current position is:");
    // console.log(`Latitude : ${crd.latitude}`);
    // console.log(`Longitude: ${crd.longitude}`);
    // console.log(`More or less ${crd.accuracy} meters.`);
    setLat(crd.latitude)
    setLong(crd.longitude)
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {

    // setAppleMap(new mapkit.Map("map"))
    if (map) {
      const region = new mapkit.CoordinateRegion(
        new mapkit.Coordinate(-2, 118),
        new mapkit.CoordinateSpan(20)
      )
      // const zoomRange = new mapkit.CameraZoomRange(15000, 15000)

      // map.cameraZoomRange = zoomRange;
      // map.cameraBoundary = region;
      map.region = region;

      // // map.cameraZoomRange = zoomRange;
      // // map.cameraBoundary = region;
      // // const region = new mapkit.BoundingRegion(northLatitude, eastLongitude, southLatitude, westLongitude);
      // // const boundingRegion = new mapkit.BoundingRegion(-6.1749759, 106.8276001, -6.1758164, 106.8267719);
      // // map.boundingRegion = boundingRegion;
      // map.center = new mapkit.Coordinate(-6.1759454, 106.8937198);
      // map.cameraDistance = 1000
      // // map.cameraDistance = 100000
    }

  }, [map])

  useEffect(() => {
    setMap(new mapkit.Map("map"))
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            // console.log(result.state);
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success);
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(success, errors, {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            });
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
    } else {
      alert("Sorry Not available!");
    }
  }, [])



  const getAccessToken = async () => {
    return await axios.get('https://maps-api.apple.com/v1/token', {
      headers: {
        Authorization: `Bearer eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkI5OEI1NlhOTjQifQ.eyJpc3MiOiJKTUZXTVpYVTVIIiwiaWF0IjoxNjgwMDE5MzgyLCJleHAiOjE2ODI4OTkyMDAsIm9yaWdpbiI6IioifQ.zRuVDY0skkZ_kmF2wfHZ0CwJXKVFPCrcX_mN_Knqvrg83NPYVRl2wDfRINHi8jWGRLYJyMTNuaDJjo-PDKY8xQ`
      }
    }).then(({ data }) => {
      return data.accessToken
    }).catch(e => {
      throw e
    })
  }

  const onSearch = async () => {
    try {
      const token = await getAccessToken()
      await axios.get('https://maps-api.apple.com/v1/search', {
        params: {
          q: search,
          userLocation: `${lat},${long}`,
          limitToCountries: 'ID',
          lang: 'id'
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(({ data }) => {
        const { results } = data
        const searchCoordinate = new mapkit.Coordinate(results?.[0]?.coordinate?.latitude, results?.[0]?.coordinate?.longitude);
        map.center = searchCoordinate
        map.cameraDistance = 1000

        const urlClick = "https://en.wikipedia.org/wiki/San_Francisco";
        // Callout delegate with left and right accessory views.
        const annotationCallout = {
          calloutLeftAccessoryForAnnotation: () => {
            const accessoryViewLeft = document.createElement("div");
            accessoryViewLeft.className = "left-accessory-view";

            const accessoryViewLeftIcon = document.createElement("span");
            accessoryViewLeftIcon.textContent = "\u{26C5}"; // Sun & Clouds
            accessoryViewLeft.appendChild(accessoryViewLeftIcon);

            const accessoryViewLeftText = document.createElement("div");
            accessoryViewLeftText.textContent = "73 \u{00b0}F";
            accessoryViewLeft.appendChild(accessoryViewLeftText);

            return accessoryViewLeft;
          },
          calloutRightAccessoryForAnnotation: () => {
            const accessoryViewRight = document.createElement("a");
            accessoryViewRight.className = "right-accessory-view";
            accessoryViewRight.href = urlClick;
            accessoryViewRight.target = "_blank";
            accessoryViewRight.textContent = "\u{24D8}"; // (i) icon

            return accessoryViewRight;
          }
        };

        const annotation = new mapkit.MarkerAnnotation(searchCoordinate, {
          title: "San Francisco",
          subtitle: "California",
          animates: true,
          selected: true,
          color: "#4B93E0",
          callout: annotationCallout,
          glyphText: ""
        });

        map.addAnnotation(annotation)

        return data
      }).catch(e => {
        throw e
      })

    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <div style={{ display: 'flex' }}>
          <input style={{ margin: "18px" }} value={search} onChange={(e) => { setSearch(e.target.value) }} />
          <button style={{ margin: "18px", width: '4rem' }} onClick={onSearch}>Cari</button>
        </div>
        <div id="map" style={{ height: '600px', width: '100%' }}></div>
      </header>
    </div>
  );
}

export default App;
